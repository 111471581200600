import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Debating Isn't Just for President's`}</h1>
    <p><strong parentName="p">{`All Access Pass - October 14, 2020`}</strong></p>
    <p>{`Hey y’all,`}</p>
    <p><strong parentName="p">{`First, if you missed it, Ben & I made this dope PDF summary of the biggest
insights from the all access pass so far.`}</strong></p>
    <p><strong parentName="p">{`Click `}<a parentName="strong" {...{
          "href": "https://docsend.com/view/jjsfyrbfppn3t68f"
        }}>{`here`}</a>{` to get it`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "674px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "179.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAkABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAECAwUE/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAe9jt4Y6CFUJLAAA/8QAGxAAAQQDAAAAAAAAAAAAAAAAAQACETEEEDD/2gAIAQEAAQUCNMdJRrGBGrUDh//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BX//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BX//EABkQAAIDAQAAAAAAAAAAAAAAAAERABAwMf/aAAgBAQAGPwKcNovP/8QAHhAAAgEEAwEAAAAAAAAAAAAAAREAECFBgSAxUWH/2gAIAQEAAT8hJEb6hEENsqnY4BSwzQgFcT5wQ7ifvH//2gAMAwEAAgADAAAAEOzOMPPP/8QAFREBAQAAAAAAAAAAAAAAAAAAESD/2gAIAQMBAT8Qa//EABURAQEAAAAAAAAAAAAAAAAAABEg/9oACAECAQE/ECv/xAAdEAEBAAICAwEAAAAAAAAAAAABEQAhMUEQcZGB/9oACAEBAAE/ENXKCzk+sa0VVoPnidRSNDlxs4SCGS0t7343XZrvLd/rnDv9wRbyei4QNj2TAQwJ2vvz/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ditch The Lists",
          "title": "Ditch The Lists",
          "src": "/static/4ae5eaa10e9274dae751c324559e7a04/deba6/ditch-the-lists.jpg",
          "srcSet": ["/static/4ae5eaa10e9274dae751c324559e7a04/a6407/ditch-the-lists.jpg 400w", "/static/4ae5eaa10e9274dae751c324559e7a04/deba6/ditch-the-lists.jpg 674w"],
          "sizes": "(max-width: 674px) 100vw, 674px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`Check it out, it’s one of the cooler things we’ve made (and in 48 hours no
less)`}</strong></p>
    <p><strong parentName="p">{`Now, back to business.`}</strong></p>
    <p>{`The idea is to build a
$1M+ online course, using nothing but our bare hands and $50 of postmates
credits.`}</p>
    <p>{`Our little detour to build the summary gave me some time to think. Did we pick
the right course topic? Should we switch lanes?`}</p>
    <p><strong parentName="p">{`Here’s our thought process:`}</strong></p>
    <p><strong parentName="p">{`Possible Course #1: learn how to buy a profitable biz`}</strong></p>
    <ul>
      <li parentName="ul">{`The customer is a second time+ entrepreneur who doesn't wanna start from
scratch again or a successful corporate person who wants to escape.`}</li>
      <li parentName="ul">{`This is a high price, low volume play which has pros and cons (harder to sell,
but easier to operate)`}</li>
    </ul>
    <p><strong parentName="p">{`Possible Course #2 - learn how to grow your audience (eg. the no-nonsense way
to get your first 10k newsletter subscribers)`}</strong></p>
    <ul>
      <li parentName="ul">{`The customer is anyone who's trying to stand out and build a name for
themselves / build an audience.`}</li>
      <li parentName="ul">{`This is a low price, high volume play.`}</li>
    </ul>
    <p><strong parentName="p">{`Possible Course #3 - Become a 10x better storyteller in 10 days`}</strong></p>
    <ul>
      <li parentName="ul">{`I’m big into storytelling, and I can’t believe more people don’t sharpen this
skill.`}</li>
      <li parentName="ul">{`It’s literally a superpower, and I think I can help people get WAY better in
10 days. I think this will help any leader, salesperson, teacher, hell even
priests & rabbi’s rely on stories to do their job.`}</li>
    </ul>
    <p><strong parentName="p">{`Debating the course topics`}</strong></p>
    <p>{`Then, we debated the topics, and used a few frameworks on how to make tough
decisions like this in our All Access Slack.`}</p>
    <p>{`Then, we took it to audio for more debate,
`}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://soundcloud.com/shaan-puri-429311607/course-topic-discussion-101420/s-cR0ChFeau4D"
        }}>{`listen in here`}</a></strong>{`.`}</p>
    <p>{`We’re going to pick one of these and sprint on it for roughly 3 weeks to bring
it to life.`}</p>
    <p>{`Hope you’re enjoying the show,`}<br parentName="p"></br>{`
`}{`Shaan`}</p>
    <Links links={[{
      title: LinkLabel.Template,
      links: [{
        url: 'https://docsend.com/view/jjsfyrbfppn3t68f',
        label: 'AAP 25 Day Takeaways'
      }]
    }, {
      title: LinkLabel.Multimedia,
      links: [{
        url: 'https://soundcloud.com/shaan-puri-429311607/chat-w-alex-soundbyte/s-xWR9TJBINBS',
        label: 'Course Topic Discussion Audio'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.were_not_selling_saddles_here.label,
      slug: slugify(postMap.were_not_selling_saddles_here.slug)
    }} nextButton={{
      text: postMap.painting_a_picture_while_tickling_the_brain.label,
      slug: slugify(postMap.painting_a_picture_while_tickling_the_brain.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      